@import 'styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');

h1 {
  font-family: 'Orbitron', sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
  color: #fff;
}

.down {
  font-size: 50px;
}

.main {
  padding: 50px 0px;
  width: 80%;
  text-align: left;

  @media only screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}

.logo {
  pointer-events: none;
  height: 150px;
  @media only screen and (max-width: 600px) {
    height: 100px;
    padding-top: 120px;
  }
}

.banner {
  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 18px;
    }
  }
}

.body__wrapper {
  background-color: #fff;
  padding-bottom: 50px;
}

.body {
  padding: 25px 0px;
  color: $dark;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  letter-spacing: 1px;
  line-height: 30px;

  h1 {
    color: #7C8184;
  }
  @media only screen and (max-width: 600px) {
    width: 90%;
    flex-direction: column;
  }

  .cabin {
    padding: 40px;
    width: 450px;
  
    @media only screen and (max-width: 600px) {
      width: 300px;
      align-self: center;
      padding: 40px 0px;
    }
  }
}

.button__main {
  font-family: 'Orbitron', sans-serif;
  border: none;
  color: #fff;
  margin: 15px 0px;
  padding: 15px 25px;
  background-color: $primary;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}

.footer {
  background-color: $dark;
  color: #fff;
  padding: 40px 0px;
  margin: auto;
  text-align: center;
}